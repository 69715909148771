// DUCKS Pattern
import { createSlice } from "@reduxjs/toolkit"

interface ModalState {
  value: boolean
}

const initialState: ModalState = {
  value: false,
}

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    toggle(state) {
      state.value = !state.value
    },
    close(state) {
      state.value = false
    },
  },
})

export const { toggle, close } = modalSlice.actions
export default modalSlice.reducer
