// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-contact-tsx": () => import("./../../../src/components/templates/contact.tsx" /* webpackChunkName: "component---src-components-templates-contact-tsx" */),
  "component---src-components-templates-default-tsx": () => import("./../../../src/components/templates/default.tsx" /* webpackChunkName: "component---src-components-templates-default-tsx" */),
  "component---src-components-templates-homepage-tsx": () => import("./../../../src/components/templates/homepage.tsx" /* webpackChunkName: "component---src-components-templates-homepage-tsx" */),
  "component---src-components-templates-service-post-type-tsx": () => import("./../../../src/components/templates/servicePostType.tsx" /* webpackChunkName: "component---src-components-templates-service-post-type-tsx" */),
  "component---src-components-templates-services-tsx": () => import("./../../../src/components/templates/services.tsx" /* webpackChunkName: "component---src-components-templates-services-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

